<template>
  <div style="background: #fff">
    <div class="box">
      <div class="header">
        <div class="imge-logo">
          <img
            v-lazy="require('../../../assets/images/logo-gw-h5.png')"
            alt=""
            class="logo"
          />
          <span class="line"></span>
          <span class="st">礼帽出行企业版</span>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="image-bg">
        <img
          v-lazy="require('../../../assets/images/h5-bg.png')"
          class="logo"
        />
        <div class="title-box">
          <div class="title-flex">
            <div class="title">礼帽出行企业版</div>
            <div class="btn-box">
              <van-button class="btn" @click="registerSubmit"
                >免费获取用车方案</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 1 -->

    <div class="items-title" style="margin-top:30px">
      <div class="one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/01.png')"
            class="image"
          />
          <div class="one-text">
            <div class="text-detail">服务优势</div>
          </div>
        </div>
      </div>
    </div>
    <div class="thrid">
      <div class="swiper-box">
        <swiper
          :autoplay="swiperOptions.autoplay"
          :loop="swiperOptions.loop"
          :slides-per-view="1.2"
          :space-between="20"
          :speed="swiperOptions.speed"
          class="swiper-container"
        >
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-01-1.png')"
                alt=""
              />
            </div>
            <div class="swiper-content">
              <div class="content-title">大品牌，值得信赖</div>
              <div class="line" style="margin-top:12px"></div>
              <div class="content-detail" style="margin-top:12px">
                礼帽出行，专注预约定制出行服务。
              </div>
              <div class="content-detail">
                由吉利控股集团旗下LEVC全新打造。
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-01-2.png')"
                alt=""
              />
            </div>
            <div class="swiper-content">
              <div class="content-title">自营车辆，安全合规</div>
              <div class="line" style="margin-top:12px"></div>
              <div class="content-detail" style="margin-top:12px">
                车辆新，定期维护，保障运营车辆的品质和安全，让出行无后顾之忧。
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-01-3.png')"
                alt=""
              />
            </div>
            <div class="swiper-content">
              <div class="content-title">出行管家，服务至上</div>
              <div class="line" style="margin-top:12px"></div>
              <div class="content-detail" style="margin-top:12px">
                统一培训，全面导入英国伦敦出租车司机服务标准资质培训认证体系，持证上岗人车实名认证，服务标准监管，优胜略汰。
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- 2 -->
    <div class="items-title" style="margin-top:-50px">
      <div class="one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/02.png')"
            class="image"
          />
          <div class="one-text">
            <div class="text-detail">多场景覆盖企业用车需求</div>
          </div>
        </div>
      </div>
    </div>
    <div class="khyc">
      <span class="khyc-left"></span>
      <span class="khyc-khyc">客户用车</span>
      <span class="khyc-right"></span>
    </div>
    <div class="second">
      <van-tabs
        :before-change="changeActive"
        v-model:active="active"
        line-height="4px"
        line-width="30px"
        background="#f5f8fd"
        title-active-color="#333333"
        title-inactive-color="#666666"
      >
        <van-tab
          title="接待用车"
          ><img
            v-lazy="require('../../../assets/images/h5-02-1.png')"
            class="image"
        /></van-tab>
        <van-tab
          title="会议用车"
          ><img
            v-lazy="require('../../../assets/images/h5-02-2.png')"
            class="image"
        /></van-tab>
        <van-tab
          title="营销用车"
          ><img
            v-lazy="require('../../../assets/images/h5-02-3.png')"
            class="image"
        /></van-tab>
        <van-tab
          title="代叫用车"
          ><img
            v-lazy="require('../../../assets/images/h5-02-4.png')"
            class="image"
        /></van-tab>
      </van-tabs>
      <div class="khyc">
        <span class="khyc-left"></span>
        <span class="khyc-khyc">员工用车</span>
        <span class="khyc-right"></span>
      </div>
      <div class="second-content">
        <div class="content-item">
          <img
            v-lazy="require('../../../assets/images/h5-03-1.png')"
            class="image"
          />
          <div class="content">日常用车</div>
        </div>
        <div class="content-item">
          <img
            v-lazy="require('../../../assets/images/h5-03-2.png')"
            class="image"
          />
          <div class="content">加班用车</div>
        </div>
        <div class="content-item" style="margin-left:10px">
          <img
            v-lazy="require('../../../assets/images/h5-03-3.png')"
            class="image"
          />
          <div class="content">出差用车</div>
        </div>
        <div class="content-item">
          <img
            v-lazy="require('../../../assets/images/h5-03-4.png')"
            class="image"
          />
          <div class="content">活动用车</div>
        </div>
      </div>
      <div class="btn-box">
        <van-button class="btn" @click="registerSubmit">立即了解</van-button>
      </div>
    </div>

    <!-- 3 -->
    <div class="items-title" style="margin-top:30px">
      <div class="one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/03.png')"
            class="image"
          />
          <div class="one-text">
            <div class="text-detail">专属座驾 LEVC TX-5</div>
          </div>
        </div>
      </div>
    </div>
    <div class="thrid">
      <div class="swiper-box">
        <swiper
          :autoplay="swiperOptions.autoplay"
          :loop="swiperOptions.loop"
          :slides-per-view="1.2"
          :space-between="20"
          :speed="swiperOptions.speed"
          class="swiper-container"
        >
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-04-1.png')"
                alt=""
              />
            </div>
            <div class="swiper-info">更舒适的乘坐体验</div>
            <div class="swiper-sinfo">对开门 大空间</div>
          </swiper-slide>
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-04-2.png')"
                alt=""
              />
            </div>
            <div class="swiper-info">更安全的出行</div>
            <div class="swiper-sinfo">全铝车身 极致工艺</div>
          </swiper-slide>
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-04-3.png')"
                alt=""
              />
            </div>
            <div class="swiper-info">更私密的洽谈</div>
            <div class="swiper-sinfo">高强度隔断 语音系统</div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- 4 -->
    <div style="background: #f5f8fd">
      <div class="items-title" style="margin-top:-60px;padding-top:30px">
        <div class="one">
          <div class="one-header">
            <img
              v-lazy="require('../../../assets/images/04.png')"
              class="image"
            />
            <div class="one-text">
              <div class="text-detail">服务支持</div>
            </div>
          </div>
        </div>
      </div>
      <div class="four">
        <div class="four-item">
          <img
            v-lazy="require('../../../assets/images/h5-05-1.png')"
            class="image"
          />
          <div class="four-content">
            <div class="content-title">运营支持</div>
            <div class="line" style="margin-top:6px"></div>
            <div class="jfy-center">
              <div class="content-detial">
                提供专业、优质、温馨的用车体验，满足多场景、个性化出行需求
              </div>
            </div>
          </div>
        </div>
        <div class="four-item">
          <img
            v-lazy="require('../../../assets/images/h5-05-2.png')"
            class="image"
          />
          <div class="four-content">
            <div class="content-title">客服支持</div>
            <div class="line" style="margin-top:6px"></div>
            <div class="jfy-center">
              <div class="content-detial">
                提供专业客服、VIP专属客服经理、业务受理及咨询、账单及费用查询、投诉及建议、紧急救助等服务
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="linkbox clearfix">
        <div>
          <div class="pull-left">
            <div>
              <span>商务联系</span>
            </div>
            <div>
              <span>友情链接</span>
            </div>
            <div style="margin-top: 48px">
              <span>平台相关</span>
            </div>
          </div>
          <div class="pull-left width50">
            <div>
              <a href="javascript:;">bd@limaocx.com</a>
            </div>
            <div>
              <div style="width: 200px">
                <a href="https://www.levcauto.com/">LEVC官网(中国)</a>
                <div><a href="https://www.levc.com/">LEVC官网(国际)</a></div>
              </div>
              <div><a href="http://zgh.com/">吉利控股集团</a></div>
              <div>
                <a href="javascript:;" @click="toAggren">保险条款</a>&nbsp;
                <a href="javascript:;" @click="toPrice">价格公示</a>&nbsp;
                <a href="javascript:;" @click="toAbout">关于我们</a>&nbsp;
                <a href="javascript:;" @click="toConnect">联系我们</a>
              </div>
            </div>
          </div>
        </div>
        <div class="pull-right">
          <div>
            <img src="../../../assets/images/home_img_weixin_nor.png" alt="" />
            <div class="gongzhong">微信公众号</div>
          </div>
        </div>
      </div>
      <div class="footerTip">
        <div>
          <a href="https://beian.miit.gov.cn">浙ICP备2021011876号</a>&nbsp;
        </div>
        <div>
          <a href="javascript:;">浙江礼帽出行科技有限公司 copyright@2021</a
          >&nbsp;<br />
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012380"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
          >
            <img
              style="vertical-align: middle"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAC3FBMVEUAAAD+/ODz6Kr//+PeqFfYrn3x167k0JXoxnyaaVzhs2ifaFXbrGLkvFnpyF7v2X/kwm3cp1nhsGfqw3rZqG3ntVzjrFPt3oDjvGnfr2fbnFGti3q0lH7ktoLryXn9v1T4znr/74bnvGz034v+2I/ktoDz6ZLkwY/Dfz7buoftzYbq2IPr0pjs3bLv6KPRrnbKhFv79ND488n/+dDZr4Lx38f/+cH/95f42oL7/97s2Y3++uzw1rvTk3DmuloAAHkBAm7uzWYAAGXktV3qvFr/0ljksE7fo0rWHxhrdocAAIAABHf143Pyy27w1GwGA2jtymHpwWDqxV/qyVyTeFrrwFflwFPislP+xVLpsErbmUfVkEbysETemUTpgj7ThT3XdTg5FDjdhTXWZTDaTCm7TCbTOCLXPiD9LA/QFg3UAwnOAQOEj5kcPpdyhZSptJEACJFpfo4AG44XMInFvYfTvIejmYSVkINyeoJzdoK9un6SjX7FrnwAEHp8enny2HjWwHjKtnhcX3jYzHeNhnfu2HWUjHWsonPNwnH70m9WTm8AAW//723pym3dtmn/0mbnxGa0o2ZeWWb8zGT/4mPtwmJuYmL/22D/vmB5ZGC9kF7/2l0MAF3uyFqnjVn4xFjYnli0mVi5i1jiqVfyyVbmtlbXkVNUOFPlvFLpt1LNrFKjfVLuvlBgHlDsuU/ouU9ONU/ov05ODk7/2E02Gk3jqkqEaUr/tUngjkf7n0bXikb6xERCJETdn0LckUG1gD/ooD3Ulj3jkz3TZT3WjjzOeDqBWDr3pDnglTlMADnbbTf2gjbkbzaTYDZpAjbplzTtcTTEazPXXzOeXzDscS3MPi38jizJWSrVSCrrXynzfCjVdCjZRyjTQCbFUiTlYCPXPSHLPSHWMR/wXh7iRh7GPh3PLBrSIRrWGhfMJxPGJxPRDBG/ABG2ABCxDg7BDAvEGArZAAbJAALPAADa4ry/AAAAPnRSTlMACEIaxqxpAvv7+ff19PDs7Ovn5uXk5OHg29LRy8fEw8G+vLqysaufnJiVk4yDfG9dXFpMSEFBNTApJyEcFO3QiBQAAAFzSURBVBjTYoACZjYZaTZmBmRgxsp9+di21ZysxggxxlmJZy/ev9LXnriIEa5VYUPIray0lOyd+ctVoKKWXFsmXXvu8exO5vsZnnuErcCC5m1e8x5nPXrxOu3TzSqHFguQmI18tff+Jx89HqR7fE5v7q5TtAYK6h8v81p4Ovv6wbAdmRc6HMpddYGCmudrCqbtTn2anHBq15SZ9iUx6kBBkSTfXIfUuBsPL909c9i/uP6EJFAQMJ6j2/Ps32Yk30uIy3jjXxgRLwEUVN07ubTo5LsPr16mXD1X29gZrgUUlN23uD/H28lp09o5TvYVs523ygEFORYsO+TbEOI5cVVTV+XUA1Fu/EBBoxXu0bfnT98cEePa45oUHR7MBHK9IV9Y/BFHFzc7R7/YqF4BsBiDqVBw0NLQoMAAF3c7vwmCEEFln1ZnZxe3wJWx7nZ2jj5qkNDU5l2/ZE3kusjQuRsDxPXYoQFqa6DBIiUmyqKkYwIWAgD35oZAL/mkFwAAAABJRU5ErkJggg=="
              alt=""
            />&nbsp;浙公网安备 33010802012380号</a
          >
        </div>
      </div>
    </div>
    <van-popup v-model:show="showForm" closeable close-icon="cross">
      <div class="popup-box">
        <div class="form">
          <van-form @submit="onSubmit">
            <van-field
              class="field"
              v-model="companyName"
              maxlength="20"
              placeholder="请输入您的企业名称"
            />

            <van-field
              class="field margin-top-field"
              v-model="areaData"
              readonly
              right-icon="arrow-down"
              placeholder="请选择省/市"
              @click="areaShow = true"
            />
            <van-popup v-model:show="areaShow" round position="bottom" teleport="body">
              <van-area
                value="330000"
                :area-list="areaList"
                :columns-num="2"
                :visible-item-count="4"
                @cancel="cancelArea"
                @confirm="confirmArea"
              >
                <template #cancel>
                  <span style="color: #787A84; font-size:15px">取消</span>
                </template>
                <template #confirm>
                  <span style="color: #186DFF; font-size: 15px">确定</span>
                </template>
              </van-area>
            </van-popup>

            <van-field
              class="field margin-top-field"
              v-model="scaleValue"
              readonly
              right-icon="arrow-down"
              placeholder="请选择企业规模"
              @click="scaleShow = true"
            />
            <van-popup v-model:show="scaleShow" round position="bottom" teleport="body">
              <van-picker
                :visible-item-count="4"
                :columns="columns"
                @cancel="onCancel"
                @confirm="onConfirm"
                value-key="text"
              >
                <template #cancel>
                  <span style="color: #787A84; font-size:15px">取消</span>
                </template>
                <template #confirm>
                  <span style="color: #186DFF; font-size: 15px">确定</span>
                </template>
              </van-picker>
            </van-popup>

            <van-field
              class="field margin-top-field"
              v-model="mobile"
              type="tel"
              maxlength="11"
              placeholder="请输入您的手机号"
            />
            <van-field
              class="field margin-top-field"
              v-model="personName"
              maxlength="20"
              placeholder="请输入您的姓名"
            />
            <div class="btn-box" style="margin-top:24px">
              <van-button
                block
                :disabled="isDisabled"
                type="primary"
                :style="{ background: isDisabled ? '#C1CCF0' : '#152FBF' }"
                class="btn"
                native-type="submit"
              >
                提交
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import { ref,watch } from "vue";
import { Toast } from 'vant';
import { register } from '@/api/home'
import{isMobile} from "../../../utils/validate"
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Autoplay, Pagination, Navigation]);
export default {
    components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
      const active = ref(0); //tabs默认显示第一个
      const showForm = ref(false); //form表单弹出框
      //popup弹框
    const areaShow = ref(false); //省市
    const scaleShow = ref(false); //公司规模
    //省市确认
    const confirmArea = (event) => {
      areaShow.value = false;
      areaData.value = `${event[0].name}/${event[1].name}`;
      province.value = event[0].code;
      city.value = event[1].code;
    };
    //省市取消
    const cancelArea = () => {
      areaShow.value = false;
    };
    //公司规模确认
    const onConfirm = (value) => {
      scaleShow.value = false;
      scaleValue.value = value.text;
      scale.value = value.value
      console.log('value:',JSON.stringify(value))
    };
    //公司规模取消
    const onCancel = () => {
      scaleShow.value = false;
    };
    // 表单数据
    const companyName = ref("");
    const areaData = ref(""); //省市整体数据
    const province = ref("");
    const city = ref("");
    const scaleValue = ref("");
    const scale = ref('')
    const mobile = ref("");
    const personName = ref("");
    const columns = [
        {
            value: '0',
            text: '1-50人'
        },
        {
            value: '1',
            text: '51-100人'
        },
        {
            value: '2',
            text: '101-500人'
        },
        {
            value: '3',
            text: '501-1000人'
        },
        {
            value: '4',
            text: '1000人以上'
        }
    ]
    //监听表单数据 规则：手机号必须11位 其余不为空
    watch([companyName, city, scale, mobile, personName,province], (newValue) => {
      if (
        newValue[0] &&
        newValue[1] &&
        newValue[2] &&
        isMobile(newValue[3]) &&
        newValue[4] &&
        newValue[5]
      ) {
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
    });
    const isDisabled = ref(true); //提交按钮默认置灰
    const registerSubmit = () => {
      showForm.value = true;
      companyName.value = ''
      areaData.value = ''
      province.value = ''
      city.value = ''
      scaleValue.value = ''
      scale.value = ''
      mobile.value = ''
      personName.value = ''
    };
    const onSubmit = ()=>{
        const data = {
             companyName: companyName.value,
             province: province.value,
             city: city.value,
             scale: scale.value,
             mobile: mobile.value,
             personName: personName.value,
             infoSource:40
        }
        console.log("data:",JSON.stringify(data))
        register(data).then(res=>{
            showForm.value = false
            if(res.code===1000){
                Toast('提交成功，我们会尽快联系您，请保持手机畅通~')
            } else {
                Toast(res.message)
            }
        })
    }
    // 02 切换tabs
    const changeActive = (index) => {
      // console.log('changeActive:',index)
      active.value = index;
    };
      return {
          active,
      //popup弹框显隐
      areaShow,
      scaleShow,
      //表单数据
      companyName, //企业名称
      areaData, //省市整体数据
      province, //省
      city, //市
      scaleValue,
      scale, //公司规模
      mobile, //手机号
      personName, //用户名
      areaList, //省市数据
      columns, //公司规模初始数据
      isDisabled, //提交按钮
      isMobile, //验证是否是手机号
      //省市确认取消事件
      cancelArea,
      confirmArea,
      //公司规模确认取消事件
      onConfirm,
      onCancel,
      showForm,
      registerSubmit,
      onSubmit,
      changeActive
    };
    },
  data() {
    return {
        swiperOptions: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
      },
    };
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/scss/gwH5.scss";
html {
  height: 100%;
}
body {
  font-size: 62.5%;
  height: 100%;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
}
</style>
<style lang="scss">
.van-tabs__line {
  background-color: #266fe8;
}
.van-popup {
  border-radius: 6px;
}
.van-popup__close-icon{
    color: #222;
    font-size: 16px;
}
.second .van-tab--active {
    font-weight: bold;
}
</style>
